import styled from 'styled-components'

export const BottomLineGoogleAdContainer = styled.div`
  margin-top: 40px;
`

export const PageSliceGoogleAdContainer = styled.div`
  margin-bottom: 64px;
`

export const TopLineGoogleAdContainer = styled.div`
  padding-top: 20px;
`
